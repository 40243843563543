<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_dialPlans") }}
            </b-card-title>
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <!-- <b-button
                @click="modal_simulation = true"
                size="sm"
                class="px-50 py-50"
                variant="flat-secondary"
              >
                <b-icon icon="bullseye"></b-icon>
              </b-button> -->
              <b-button
                @click="openClassificationModal"
                size="sm"
                class="px-50 py-50"
                variant="flat-secondary"
              >
                <b-icon icon="bezier2"></b-icon>
              </b-button>
              <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
                <b-icon icon="funnel"></b-icon>
              </b-button>
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              hover
              :fields="tableColumns"
              :items="records"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="records.length == 0"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template #cell(save_record)="data">
                <feather-icon
                  :icon="data.value ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(line)="data">
                {{
                  lines.find((e) => e.internal_name == data.value)
                    ? lines.find((e) => e.internal_name == data.value)
                        .display_name
                    : data.value
                }}
              </template>
              <template #cell(line_group)="data">
                {{
                  line_groups.find((e) => e.internal_name == data.value)
                    ? line_groups.find((e) => e.internal_name == data.value)
                        .display_name
                    : data.value
                }}
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    records.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="records.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_record"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="id"
            rules="required"
          >
            <b-form-group :label="lang('t_id')" label-for="id">
              <b-form-input
                id="id"
                v-model="currentData.priority"
                :state="getValidationState(validationContext)"
                trim
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- callingPatterns -->
          <validation-provider
            #default="validationContext"
            name="callingPatterns"
            rules="required"
          >
            <b-form-group
              :label="lang('t_callingPatterns')"
              label-for="callingPatterns"
            >
              <b-form-input
                id="callingPatterns"
                v-model="currentData.call_pattern"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="classification"
            rules="required"
          >
            <b-form-group
              :label="lang('t_classification')"
              label-for="classification"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="classification"
                v-model="currentData.classification"
                :options="classifications"
                :placeholder="lang('t_classification')"
                label="name"
                value="id"
              >
                <template #option="{ name, queue }">
                  <h4 style="margin: 0">{{ name }}</h4>
                  <em>
                    {{
                      queues.find((e) => e.internal_name == queue).display_name
                    }}</em
                  >
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- callingPatterns -->
          <validation-provider
            #default="validationContext"
            name="dialPattern"
            rules="required"
          >
            <b-form-group
              :label="lang('t_dialPattern')"
              label-for="dialPattern"
            >
              <b-form-input
                id="dialPattern"
                v-model="currentData.orientation_pattern"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Plan -->
          <validation-provider
            #default="validationContext"
            name="lineGroup"
            rules="required"
          >
            <b-form-group
              :label="lang('t_lineGroup')"
              label-for="lineGroup"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="currentData.line_group"
                :options="line_groups"
                :placeholder="lang('t_lineGroup')"
                :reduce="(val) => val.internal_name"
                label="display_name"
                value="internal_name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{lang('t_add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{lang('t_cancel')}}
            </b-button>
          </div>
          -->
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- classifications -->
    <b-modal
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_classifications')"
      centered
      v-model="modal_classifications"
      hide-footer
      ok-only
      :ok-title="lang('t_close')"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Name -->

        <!-- classification -->
        <b-row>
          <b-col cols="6">
            <b-form-group :label="lang('t_queue')" label-for="queue">
              <v-select
                :disabled="isEditClassification"
                id="queue"
                v-model="new_queue_name"
                :options="queues"
                :placeholder="lang('t_queue')"
                :reduce="(val) => val.internal_name"
                label="display_name"
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="lang('t_classification')"
              label-for="classification"
            >
              <b-form-input
                :disabled="isEditClassification"
                id="classification"
                v-model="new_classification_name"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              :label="lang('t_internationalCall')"
              label-for="t_internationalCall"
            >
              <b-form-checkbox
                class="custom-control-primary mt-75"
                name="check-button"
                switch
                v-model="new_international_call"
              >
                <span class="switch-icon-left">
                  <feather-icon class="mt-25" icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon class="mt-25" icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group :label="lang('t_survey')" label-for="t_survey">
              <b-form-checkbox
                class="custom-control-primary mt-75"
                name="check-button"
                switch
                v-model="new_send_survey"
              >
                <span class="switch-icon-left">
                  <feather-icon class="mt-25" icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon class="mt-25" icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="lang('t_queueMusicOnHold')"
              label-for="t_queueMusicOnHold"
            >
              <v-select
                v-model="new_music_on_hold"
                :options="announcements"
                label="display_name"
                :reduce="(val) => val.internal_name"
                :placeholder="lang('t_queueMusicOnHold')"
              />
            </b-form-group>
          </b-col>

          <b-col v-if="new_send_survey" cols="6">
            <b-form-group :label="lang('t_target')" label-for="t_target">
              <v-select
                v-model="new_survey_target"
                :options="survey"
                label="display_name"
                :reduce="(val) => val.internal_name"
                :placeholder="lang('t_survey')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              class="mb-1 float-right"
              type="button"
              variant="primary"
              @click="addClassification"
            >
              {{ lang("t_save") }}
            </b-button>
          </b-col>
        </b-row>

        <b-table
          style="min-height: 300px; max-height: 500px; overflow-y: auto"
          ref="refUserListTable"
          class="position-relative"
          :items="classifications"
          responsive
          :fields="tableColumns_classification"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          small
          striped
          :empty-text="lang('t_noRecord')"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Status -->
          <template #cell(queue)="data">
            {{
              queues.find((e) => e.internal_name == data.item.queue)
                .display_name
            }}
          </template>
          <template #cell(international_call)="data">
            <feather-icon
              :icon="data.value ? 'CheckIcon' : 'XIcon'"
              size="12"
              class="align-middle text-body"
            />
          </template>
          <template #cell(music_on_hold)="data">
            {{
              announcements.find((e) => e.internal_name == data.value)
                ? announcements.find((e) => e.internal_name == data.value)
                    .display_name
                : data.value
            }}
          </template>

          <!-- Column: Actions -->

          <template #cell(actions)="data">
            <!-- <b-button
              size="sm"
              type="button"
              variant="outline-danger"
              @click="deleteClassification(data.item)"
            >
              {{ lang("t_delete") }}
            </b-button> -->

            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="updateSelectedClassification(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="confirmTextClassification(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <!-- Form Actions
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{lang('t_add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{lang('t_cancel')}}
            </b-button>
          </div>
          -->
      </b-form>
    </b-modal>
    <!-- simulation -->
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_simulation')"
      centered
      v-model="modal_simulation"
      :ok-title="lang('t_check')"
      :cancel-title="lang('t_close')"
      @ok="
        check_number();
        simulation_input = '';
      "
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->

          <!-- classification -->
          <validation-provider
            #default="validationContext"
            name="number"
            rules="required"
          >
            <b-form-group :label="lang('t_number')" label-for="number">
              <b-form-input
                id="number"
                :state="getValidationState(validationContext)"
                trim
                v-model="simulation_input"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <label id="sim_text"> </label>

          <!-- Form Actions
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{lang('t_add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{lang('t_cancel')}}
            </b-button>
          </div>
          -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // UsersListFilters,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCardText,
    vSelect,
  },
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.records.slice(start, end);
    },

  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      isEditClassification: false,
      simulation_progress: false,
      simulation_input: "",
      currentData: {
        id: "",
        priority: "",
        call_pattern: "XXXXXXXXXXX",
        classification: "",
        orientation_pattern: "1-2-3-4-5-6-7-8-9-10-11",
        line_group: "",
      },
      modal_record_details: false,
      modal_classifications: false,
      modal_simulation: false,
      required,
      alphaNum,
      email,
      password,
      new_classification_name: "",
      new_queue_name: "",
      new_music_on_hold: "quiet",
      new_international_call: false,
      new_send_survey: false,
      new_survey_target: null,
      line_groups: [],
      queues: [],
      survey: [],
      // line_groups: ["Test_HG", "FULL_HG"],
      classifications: [],
      tableColumns: [
        { key: "priority", label: globalThis._lang("t_order"), sortable: true },
        {
          key: "call_pattern",
          label: globalThis._lang("t_callingPatterns"),
          sortable: true,
        },
        {
          key: "classification",
          label: globalThis._lang("t_classification"),
          sortable: true,
        },
        {
          key: "orientation_pattern",
          label: globalThis._lang("t_dialPattern"),
          sortable: true,
        },
        {
          key: "line_group",
          label: globalThis._lang("t_lineGroup"),
          sortable: true,
        },
        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }
        },
      ],
      tableColumns_classification: [
        {
          key: "name",
          label: globalThis._lang("t_classification"),
          sortable: true,
        },
        { key: "queue", label: globalThis._lang("t_queue"), sortable: true },
        {
          key: "music_on_hold",
          label: globalThis._lang("t_queueMusicOnHold"),
          sortable: true,
        },
        {
          key: "international_call",
          label: globalThis._lang("t_internationalCall"),
          sortable: true,
        },
        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }
        },
      ],
      records: [],
      dial_plans: [],
      announcements: [],
    };
  },
  mounted: function () {
    this.get_records();
    this.get_line_groups();
    this.get_classification();
    this.get_survey();
    this.get_queues();
    this.GetAnnouncements();
  },
  methods: {
    // on_change_classification(val){
    //   this.classification_id
    // },
    openClassificationModal() {
      this.modal_classifications = true;
      this.new_classification_name = '';
      this.new_queue_name = '';
      this.new_music_on_hold = "quiet";
      this.new_international_call = false;
      this.new_send_survey = false;
      this.new_survey_target = null;
      this.new_music_on_hold = "quiet";
      this.isEditClassification = false;
    },
    updateSelectedClassification: async function (item) {
      this.isEditClassification = true;
      console.log("item", item);
      this.new_queue_name = item.queue;
      this.new_classification_name = item.name;
      this.new_international_call = item.international_call;
      this.new_music_on_hold = item.music_on_hold;
      this.new_send_survey = item.send_survey;
      this.new_survey_target = item.survey_target;
    },
    GetAnnouncements: async function () {
      var response = (await this.$http_in.get(`optmgr/v1/MusicOnHold`)).data;

      this.announcements.push({
        internal_name: "quiet",
        display_name: globalThis._lang("t_none"),
      });
      this.announcements.push({
        internal_name: "default",
        display_name: "Standart",
      });
      this.announcements = [...this.announcements, ...response];
    },
    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/smgr/v1/DialPlan`)).data;
      console.log("DialPlan", response);
      this.records = response;
      this.is_progress = false;
    },
    get_line_groups: async function () {
      var response = (await this.$http_in.get(`/smgr/v1/LineGroup`)).data;
      console.log("LineGroup", response);
      this.line_groups = response;
    },
    get_classification: async function () {
      var response = (await this.$http_in.get(`/smgr/v1/Classification `)).data;
      console.log("Classification", response);
      this.classifications = response;
    },
    get_survey: async function () {
      var response = (await this.$http_in.get(`/smgr/v1/SurveyIvr`)).data;
      this.survey = response;
    },
    get_queues: async function () {
      this.queues = [];
      var response = (await this.$http_in.get(`/smgr/v1/Queue `)).data;
      this.queues.push({
        internal_name: "undefined",
        display_name: globalThis._lang('t_undefined'),
      });
      this.queues = [...this.queues, ...response];
    },
    addClassification: async function () {
      if (this.new_classification_name.trim().length == 0 || this.new_queue_name.trim().length == 0) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.is_progress = true;

      if (!this.isEditClassification) {
        var response = (
          await this.$http_in.post(`/smgr/v1/Classification`, {
            data: {
              name: this.new_classification_name,
              queue: this.new_queue_name,
              music_on_hold: this.new_music_on_hold,
              international_call: this.new_international_call,
              send_survey: this.new_send_survey,
              survey_target: this.new_survey_target,
            },
          })
        ).data;
      } else {
        var response = (
          await this.$http_in.put(`/smgr/v1/Classification`, {
            data: {
              name: this.new_classification_name,
              queue: this.new_queue_name,
              music_on_hold: this.new_music_on_hold,
              international_call: this.new_international_call,
              send_survey: this.new_send_survey,
              survey_target: this.new_survey_target,
            },
          })
        ).data;
      }

      this.get_classification();
      this.new_classification_name = '';
      this.new_queue_name = '';
      this.new_music_on_hold = "quiet";
      this.new_international_call = false;
      this.new_send_survey = false;
      this.new_survey_target = null;
      this.new_music_on_hold = "quiet";
      this.isEditClassification = false;


      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.is_progress = false;
    },
    deleteClassification: async function (item) {
      console.log(item);

      try {
        this.is_progress = true;
        var response = (
          await this.$http_in.delete(`/smgr/v1/Classification/${item._id}`)
        ).data;
      }
      catch (error) {
        if (error && error.response && error.response.data) {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: `${error.response.data} kullanıldığı için kayıt silinemiyor.`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } finally {
        this.is_progress = false;
      }

      this.get_classification();
    },
    check_number() {
      this.is_alert = false;
      this.simulation_progress = true;

      /*
        $target_number = (strlen($dizi['agi_extension']) > 10) ? substr($dizi['agi_extension'], -10) : $dizi['agi_extension'];
        $call_pattern_mask = preg_replace('/./', 'X', $target_number);
        $internal_prefix = (strlen($dizi['agi_extension']) > 10) ? str_replace($target_number, "", $dizi['agi_extension']) : "";
    
        $blacklist_query = "select * from voyce_core.voyce_blacklist where number LIKE '%".substr($target_number, -10)."' AND is_outbound = 't'";
      */

      let normalized_number =
        this.simulation_input.length > 10
          ? this.simulation_input.substr(-10)
          : this.simulation_input;
      let call_pattern_mask = normalized_number.replace(/./g, "X");
      let internal_prefix =
        this.simulation_input.length > 10
          ? this.simulation_input.replace(normalized_number, "")
          : "";

      console.log(normalized_number);
      // let prefix = this.simulation_input.replace(normalized_number, "");
      console.log(internal_prefix);
      let call_pattern = `${internal_prefix}${call_pattern_mask}`;
      console.log(call_pattern);

      let sorted_dialplans = this.records.sort((a, b) => {
        return a.priority - b.priority;
      });
      console.log(sorted_dialplans);
      let dialplan = sorted_dialplans.find(
        (d) => d.call_pattern == call_pattern
      );
      console.log("call_pattern", call_pattern);
      console.log("dialplan", dialplan);
      if (dialplan) {
        this.simulation_progress = false;
        this.$swal({
          title: globalThis._lang("t_showing"),
          text: `${globalThis._lang("t_simulatedCallCouldBePlacedFrom")} ${dialplan.priority
            }`,
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

        // error
      } else {
        this.simulation_progress = false;
        this.$swal({
          title: globalThis._lang("t_noRecord"),
          text: globalThis._lang("t_couldNotPlaceSimulationCall"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },

    check_data() {
      if (
        this.currentData.priority == "" ||
        this.currentData.call_pattern == "" ||
        this.currentData.classification == "" ||
        this.currentData.orientation_pattern == "" ||
        this.currentData.line_group == ""
      ) {
        return true;
      }
      return false;
    },
    add_record: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      let _to_send = {
        priority: this.currentData.priority,
        call_pattern: this.currentData.call_pattern,
        orientation_pattern: this.currentData.orientation_pattern,
        line_group: this.currentData.line_group,
        classification: this.currentData.classification.name,
        queue: this.currentData.classification.queue,
      };
      this.is_progress = true;
      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(`/smgr/v1/DialPlan`, {
            data: JSON.stringify(_to_send),
          })
        ).data;
      } else {
        _to_send._id = this.currentData._id;
        response = (
          await this.$http_in.put(`/smgr/v1/DialPlan`, {
            data: JSON.stringify(_to_send),
          })
        ).data;
      }
      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.delete(`/smgr/v1/DialPlan/${this.currentData._id}`)
      ).data;
      this.is_progress = false;

      if (response && response.acknowledged) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
      this.get_line_groups();
      this.get_classification();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        id: "",
        priority: "",
        call_pattern: "XXXXXXXXXXX",
        classification: "",
        orientation_pattern: "1-2-3-4-5-6-7-8-9-10-11",
        line_group: "",
      };
    },
    updateSelected(item) {
      this.currentData = {
        ...item,
        classification: this.classifications.find(
          (e) => e.queue == item.queue && e.name == item.classification
        ),
      };
      console.log("item", item);
      this.isEdit = true;
      // this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    confirmTextClassification(item) {
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteClassification(item);
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const groupOptions = [
      { label: "Grup1", value: "123" },
      { label: "Grup4", value: "456" },
      { label: "Grup3", value: "9999" },
      { label: "Grup2", value: "654" },
    ];
    const classificationOptions = [
      { label: "5XX", value: "123" },
      { label: "905XX", value: "456" },
      { label: "5105XX", value: "9999" },
      { label: "5405XX", value: "654" },
      { label: "isnet", value: "654" },
    ];
    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      groupOptions,
      classificationOptions,
      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
